.col{
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 0;
	padding-bottom: 0;
}

.projectCards:hover {
	box-shadow: 0 0 10px 1px rgba(255, 140, 140, 0.8);
	transform: scale(1.02);
	transition-duration: 300ms, 500ms;
	transition-property: transform, box-shadow;
}

.projectCards img{
	max-height: 225px;
}

#websiteImage{
	max-width: 280px;
}

#project-grid {
	display: inline-grid;
	/* color: black; */
	gap: 3rem 2rem;
	grid-template-columns: repeat(2, 40vw);
	padding-bottom: 100px;
	padding-top: 20px;
}

#project-grid a{
	text-decoration: none;
}


@media screen and (max-width: 900px) {
	#project-grid {
		display: grid;
		/* color: black; */
		gap: 0rem 0rem;
		grid-template-columns: repeat(auto-fill, 100vw);
		padding-bottom: 100px;
		padding-top: 20px
	}
}
