#contact-grid {
	display: inline-grid;
	/* color: black; */
	gap: 3rem 2rem;
	grid-template-columns: repeat(2, 40vw);
	padding-bottom: 100px;
	padding-top: 50px;
	font-size: 18px;
	text-align: center;
}

@media screen and (max-width: 900px) {
	#contact-grid {
		display: grid;
		/* color: black; */
		gap: 3rem 2rem;
		grid-template-columns: repeat(auto-fill, 100vw);
		padding-bottom: 100px;
		padding-top: 50px;
		font-size: 18px;
	}
}

#contact-grid a{
	color: white;
}
