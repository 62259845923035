.profileCard {
	/* Add shadows to create the "card" effect */
	box-shadow: 0 4px 8px 0 rgba(255,255,255,0.2);
	background: black;
	max-width: 350px;
	text-align: center;
	transition: 0.3s;
	font-family: abel;
}


/* On mouse-over, add a deeper shadow */
.profileCard:hover {
	box-shadow: 0 8px 16px 0 rgba(255,255,255,0.2);
}

/* Add some padding inside the card container */
.detailsContainerProfileCard {
	padding: 2px 16px;
}

.profileCardImage {
	border-radius: 50%;
	margin: 30px
}

.title {
	color: grey;
	font-size: 18px;
}
